<template>
  <div class="top-social bg-primary text-white">
    <div
      class="flex gap-2 justify-center md:justify-end py-2 px-4 marker:max-w-7xl w-full mx-auto"
    >
      <div class="mx-2">
        <a href="tel://+2349033717606">+234.903.3717.606</a>
      </div>
      <a href="https://www.facebook.com/wednl">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.907"
          height="21.907"
          viewBox="0 0 21.907 21.907"
        >
          <path
            id="Icon_awesome-facebook-square"
            data-name="Icon awesome-facebook-square"
            d="M19.56,2.25H2.347A2.347,2.347,0,0,0,0,4.6V21.81a2.347,2.347,0,0,0,2.347,2.347H9.059V16.709H5.978V13.2H9.059V10.532c0-3.039,1.809-4.718,4.58-4.718a18.663,18.663,0,0,1,2.715.237V9.033H14.825a1.753,1.753,0,0,0-1.977,1.894V13.2h3.363l-.538,3.506H12.848v7.448H19.56a2.347,2.347,0,0,0,2.347-2.347V4.6A2.347,2.347,0,0,0,19.56,2.25Z"
            transform="translate(0 -2.25)"
          /></svg
      ></a>
      <a href="https://twitter.com/wednlng">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.907"
          height="21.907"
          viewBox="0 0 21.907 21.907"
        >
          <path
            id="Icon_awesome-twitter-square"
            data-name="Icon awesome-twitter-square"
            d="M19.56,2.25H2.347A2.348,2.348,0,0,0,0,4.6V21.81a2.348,2.348,0,0,0,2.347,2.347H19.56a2.348,2.348,0,0,0,2.347-2.347V4.6A2.348,2.348,0,0,0,19.56,2.25Zm-2.391,7.765c.01.137.01.279.01.416a9.062,9.062,0,0,1-9.125,9.125A9.083,9.083,0,0,1,3.13,18.118a6.757,6.757,0,0,0,.773.039,6.43,6.43,0,0,0,3.98-1.369,3.211,3.211,0,0,1-3-2.225A3.456,3.456,0,0,0,6.332,14.5a3.207,3.207,0,0,1-2.567-3.149v-.039a3.2,3.2,0,0,0,1.447.406,3.2,3.2,0,0,1-1.428-2.67A3.167,3.167,0,0,1,4.22,7.433a9.106,9.106,0,0,0,6.611,3.354A3.214,3.214,0,0,1,16.3,7.859a6.284,6.284,0,0,0,2.034-.773,3.2,3.2,0,0,1-1.408,1.765,6.382,6.382,0,0,0,1.848-.5A6.75,6.75,0,0,1,17.169,10.015Z"
            transform="translate(0 -2.25)"
          /></svg
      ></a>
      <a href="https://www.instagram.com/westenddiamondltd/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.454"
          height="21.454"
          viewBox="0 0 21.454 21.454"
        >
          <path
            id="Icon_metro-instagram"
            data-name="Icon metro-instagram"
            d="M20.463,1.928H6.132A3.572,3.572,0,0,0,2.571,5.49V19.82a3.572,3.572,0,0,0,3.562,3.562h14.33a3.572,3.572,0,0,0,3.562-3.562V5.49a3.572,3.572,0,0,0-3.562-3.562ZM9.438,11.314h7.718a4.085,4.085,0,1,1-7.718,0Zm11.9,0v8.044A1.345,1.345,0,0,1,20,20.7H6.593a1.345,1.345,0,0,1-1.341-1.341V11.314h2.1a6.1,6.1,0,1,0,11.9,0h2.1Zm0-4.694a.672.672,0,0,1-.67.67H19.331a.672.672,0,0,1-.67-.67V5.28a.672.672,0,0,1,.67-.67h1.341a.672.672,0,0,1,.67.67Z"
            transform="translate(-2.571 -1.928)"
          /></svg
      ></a>
      <a
        href="https://www.linkedin.com/company/westend-diamond-nigeria-limited/mycompany/?viewAsMember=true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21.907"
          height="21.907"
          viewBox="0 0 21.907 21.907"
        >
          <path
            id="Icon_awesome-linkedin"
            data-name="Icon awesome-linkedin"
            d="M20.342,2.25H1.56A1.572,1.572,0,0,0,0,3.829V22.577a1.572,1.572,0,0,0,1.56,1.579H20.342a1.576,1.576,0,0,0,1.565-1.579V3.829A1.576,1.576,0,0,0,20.342,2.25ZM6.621,21.027H3.374V10.573H6.626V21.027ZM5,9.145A1.883,1.883,0,1,1,6.88,7.262,1.883,1.883,0,0,1,5,9.145ZM18.792,21.027H15.545V15.942c0-1.213-.024-2.773-1.687-2.773-1.692,0-1.951,1.32-1.951,2.685v5.174H8.66V10.573h3.115V12h.044a3.42,3.42,0,0,1,3.076-1.687c3.286,0,3.9,2.166,3.9,4.983Z"
            transform="translate(0 -2.25)"
          /></svg
      ></a>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.top-social a {
  @apply inline-block text-white hover:text-quartenary transition-all duration-150;
}
</style>
