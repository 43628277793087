// stores/counter.js
import { Resource, ResourceMapped, WebContent } from "@/types/interfaces";
import axios from "axios";
import { defineStore, acceptHMRUpdate } from "pinia";
import { encode, decode } from "js-base64";
import { isEmpty } from "lodash";
import { AvailableCourse, CourseSession } from "@/views/Course/courses-types";
interface Photos {
  home: Resource[];
  products: Resource[];
  about: Resource[];
  blog: Resource[];
  api: Resource[];
  contact: Resource[];
  navbar: Resource[];
}

export const useMain = defineStore("main", {
  state: () => {
    return {
      siteLoading: true,
      baseUrl: "https://app.wednlacademy.com/headless/v1/",
      apiRUL: "https://app.wednlacademy.com/headless/v1/",
      photoUrl: "https://app.wednlacademy.com/uploads/",
      token: "1|sWA3WL41VIvVecAJpXw4nU5pHkaDI842txW8YeUk",
      ogImage: "https://app.wednlacademy.com/uploads/ri-62f1df736c32e.jpg",
      ogTitle: "",
      ogDescription: "",
      ogUrl: "",
      selectedCourseSession: {} as CourseSession,
      courseSessions: [] as CourseSession[],
      selectedAvailableCourse: {} as AvailableCourse,
      availableCourses: [] as AvailableCourse[],
      showCoursePhoto: true,
      userAgent:
        "AngularPay-VueJs-Client-v1.0-M51316-70e5e368-eeb6-4ba5-9d02-0bdbb1037edc",
      // homeIllustrationOne: "",
      // homeIllustrationTwo: "",
      userAgentObject: {
        "x-angularpay-user-agent":
          "AngularPay-VueJs-Client-v1.0-M51316-70e5e368-eeb6-4ba5-9d02-0bdbb1037edc",
      },
      homeIllustrations: {
        home: [],
        products: [],
        about: [],
        blog: [],
        contact: [],
        api: [],
        navbar: [],
      } as Photos,
      homeIllustrationsRemapped: {} as ResourceMapped,
      webContent: [] as WebContent[],
    };
  },
  actions: {
    toImageDataURL(resource: any) {
      if (resource) {
        // console.log("The Image___", resource);
        if (resource.type && resource.type.toLowerCase() === "svg") {
          const value = resource.base_64_image_string;
          const base64 =
            value.substring(value.length - 2, value.length) == "=="
              ? value.substring(0, value.length - 2)
              : value;
          const source = `data:image/${resource.type}+xml;base64,${base64}`;
          return source;
          // return domSanitizer.bypassSecurityTrustResourceUrl(source);
        }
        return `data:image/${resource.type};base64,${resource.base_64_image_string}`;
      } else {
        return "";
      }
    },
    getResource(url: string, page = "home") {
      this.siteLoading = true;
      let str = "";
      if (url) {
        axios
          .get(url, {
            headers: {
              "x-angularpay-user-agent": this.userAgent,
            },
          })
          .then((response) => {
            // str = this.toImageDataURL(response.data);
            // console.log(toImageDataURL(response.data));
            // this.homeIllustrations = response.data;
            // console.log(url, "Loading for ", page);
            const items = response.data.map((item: Resource) => {
              return {
                ...item,
                photo: this.toImageDataURL(item),
              } as Resource;
            });

            this.homeIllustrations = {
              ...this.homeIllustrations,
              [page]: items,
            };
            //@ts-ignore
            // console.log(JSON.stringify(this.homeIllustrations[page], null, 2));
            this.siteLoading = false;
          })
          .catch((error) => {
            console.log(error);
            str = "";
            this.siteLoading = false;
          });
      }
    },
    getWebContents() {
      this.siteLoading = true;
      // console.log("web content", `${this.baseUrl}/corporate/static/content`);
      axios
        .get(`${this.baseUrl}/corporate/static/content`, {
          headers: {
            "x-angularpay-user-agent": this.userAgent,
          },
        })
        .then((response) => {
          this.webContent = response.data.map((item: WebContent) => {
            return { ...item, content: decode(item.content) };
          });

          if (isEmpty(this.homeIllustrations.home))
            this.getResource(
              `${this.baseUrl}/corporate/static/resources?category=corporate-site-home`,
              "home"
            );
          if (isEmpty(this.homeIllustrations.about))
            this.getResource(
              `${this.baseUrl}/corporate/static/resources?category=corporate-site-about`,
              "about"
            );
          if (isEmpty(this.homeIllustrations.products))
            this.getResource(
              `${this.baseUrl}/corporate/static/resources?category=corporate-site-products`,
              "products"
            );
          if (isEmpty(this.homeIllustrations.api))
            this.getResource(
              `${this.baseUrl}/corporate/static/resources?category=corporate-site-open-api`,
              "api"
            );
          if (isEmpty(this.homeIllustrations.blog))
            this.getResource(
              `${this.baseUrl}/corporate/static/resources?category=corporate-site-blog`,
              "blog"
            );
          if (isEmpty(this.homeIllustrations.contact))
            this.getResource(
              `${this.baseUrl}/corporate/static/resources?category=corporate-site-contact`,
              "contact"
            );
          // console.log(JSON.stringify(this.webContent));
          this.siteLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.siteLoading = false;
        });
    },
    getResourcePhoto(name: string, page = "home") {
      // return this.homeIllustrations.find((item: Resource) => {
      //   return item.name === name.trim();
      // });
    },
  },
});

if (import.meta.webpackHot) {
  // console.log("meta.webpackhot");
  import.meta.webpackHot.accept(
    acceptHMRUpdate(useMain, import.meta.webpackHot)
  );
}
