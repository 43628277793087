import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeLanding from "../views/Home/HomeMain.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeLanding,
  },
  {
    path: "/course/:id",
    name: "courseDetail",
    component: () =>
      import(
        /* webpackChunkName: "courses" */ "../views/Course/CourseDetail.vue"
      ),
    meta: {
      title: "Course Details - ",
    },
  },
  {
    path: "/courses",
    name: "courses",
    component: () =>
      import(
        /* webpackChunkName: "courses" */ "../views/Course/CoursesAll.vue"
      ),
    meta: {
      title: "Course Calendar - ",
    },
  },
  {
    path: "/courses/calendar",
    name: "courseCalendar",
    component: () =>
      import(
        /* webpackChunkName: "courses" */ "../views/Course/CourseCalendar.vue"
      ),
    meta: {
      title: "Course Calendar - ",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/AboutUs/AboutUsMain.vue"
      ),
  },
  {
    path: "/explore-open-api",
    name: "explore-api",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ExploreApi/ExploreApiMain.vue"
      ),
  },

  {
    path: "/blogs",
    name: "blogs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Blogs/BlogMain.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Contact/ContactMain.vue"
      ),
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Products/ProductsMain.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    // console.log("ROUTE SCROLL BEHAVIOR", to, from, savedPosition);
    return { top: 0 };
  },
});

export default router;
