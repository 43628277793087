import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

//VUE META
import { createMetaManager } from "vue-meta";
// import { VueReCaptcha } from "vue-recaptcha-v3";

//PRIMEVUE
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
import Sidebar from "primevue/sidebar";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import ScrollPanel from "primevue/scrollpanel";

import "./registerServiceWorker";
import router from "./router";
// import store from "./store";

//CSS
import "primevue/resources/themes/md-light-indigo/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "@/css/index.css";
import "@/css/index.scss";

// import "mdb-vue-ui-kit/css/mdb.min.css";

import "material-icons/iconfont/material-icons.css";
import "@mdi/font/css/materialdesignicons.min.css";

const app = createApp(App);
// app.use(store);
app.use(createPinia());
app.use(router);
app.use(PrimeVue);
// app.use(VueReCaptcha, { siteKey: "6Lfhq7UeAAAAAOc2RPZs4ZXay-Ct8S02lFq_kKOT" });
//@ts-ignore
app.use(createMetaManager(), {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

app.component("Dialog", Dialog);
app.component("Dropdown", Dropdown);
app.component("Button", Button);
app.component("Sidebar", Sidebar);
app.component("ScrollPanel", ScrollPanel);

app.mount("#app");

defineCustomElements(window);
