<template>
  <div
    class="my-element bg-gradient-to-b from-primary via-slate-600 to-purple-800 text-white fixed z-[5000] inset-0 w-full h-full flex justify-center items-center"
  >
    <div>
      <svg
        width="71"
        height="71"
        viewBox="0 0 71 71"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="the-logo w-auto h-48 fill-current"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.2081 9.12162C18.9923 13.1955 14.824 23.9689 18.8979 33.1847L21.623 39.3494L29.9735 36.9183L26.7823 29.6993C24.6334 24.838 26.8322 19.1551 31.6935 17.0061C36.348 14.9485 41.7557 16.8767 44.0936 21.3114L36.5904 24.6514L40.2642 32.9044L48.0452 29.1865L48.0293 29.1509L49.2363 28.6174L55.4593 25.6439L52.2712 18.4319C48.1973 9.21608 37.4239 5.04774 28.2081 9.12162ZM48.6314 30.5035L40.2993 32.9833L43.8182 40.8885C45.9798 45.7442 43.7956 51.4328 38.9399 53.5943C34.0841 55.7559 28.3955 53.5717 26.234 48.716L25.9211 48.013L33.4138 44.7008L30.0241 37.0328L14.9708 44.6113L18.3586 52.2217C22.4562 61.4269 33.2404 65.5674 42.4456 61.4698C51.6508 57.3721 55.7913 46.588 51.6937 37.3827L48.6314 30.5035Z"
        />
      </svg>
      <div class="text-center text-4xl font-bold">Westend Diamond Academy</div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
@keyframes animatebg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes rotatelogo {
  0% {
    transform: rotate(0deg) translateY(0);
  }
  25% {
    transform: rotate(-5deg) translateY(-2px);
  }
  75% {
    transform: rotate(5deg) translateY(2px);
  }
  100% {
    transform: rotate(0deg) translateY(0);
  }
}

.the-logo {
  @apply rotate-0 origin-center;
  will-change: transform, opacity;
  animation: rotatelogo1 10s infinite alternate ease-in-out;
}

.my-element {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(
    -90deg,
    #08243b 0%,
    /* rgba(87, 111, 230, 1) 25%,
    rgba(152, 68, 183, 1) 51%, */ #4382b6 100%
  );
  animation: animatebg 6s infinite alternate;
}
</style>
